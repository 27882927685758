import  { useEffect, useState } from 'react';
import "../styles/gallerybtn.css";
import img1 from "../images/poojs.png";
import img2 from "../images/two.jpg";
import img3 from "../images/three.jpg";

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


const GalleryAll = () => {

const [data, setData] = useState([]);

const getData = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/images')
        setData(await response.json());
    }

    useEffect(() => {
        getData();
    }, []);

const onInit = () => {
        console.log('lightGallery has been initialized');
    };

  return (
    <>
      

      <div className='gallery_common_design container'>

       <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >

       {

                             data.map((curElem, index) => {
                                
                                    return (

                
                                     <a href={"https://admin.codesquarry.com/uploads/"+curElem.image}>
                                        <img className='gallery_img' width="250px" alt="img1" src={"https://admin.codesquarry.com/uploads/"+curElem.image} />
                                     </a>
                

                                    )
                                })

                  }

        </LightGallery>


       
          
      

      </div>

      

    </>
  )
}


export default GalleryAll;
