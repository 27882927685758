import React, { useState } from "react";
import "../styles/about.css";
import img1 from "../images/about.jpeg";

function About() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    message: ''
  });

    const [successMessage, setSuccessMessage] = useState(""); // Add this


  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch('https://admin.codesquarry.com/api/enquiry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
     .then(data => {
      console.log(data);
      setSuccessMessage("Submitted successfully"); // Set success message
      setFormData({
      name: '',
      email: '',
      phone: '',
      country: '',
      message: ''
    }); // Reset form data
    // setButtonDisabled(true); // Disable submit button
    setTimeout(() => {
      setSuccessMessage(null); // Hide success message after 3 seconds
     // setButtonDisabled(false); // Enable submit button
    }, 3000);

    })
    .catch(error => console.log(error));
  };
    return (
        <>
            <div className='about_Box container'>

                <div className='row'>

                    <div className='about_Left col-sm-12 col-md-8 col-lg-8'>



                        <p className='about_para'>
                            Karyanayil Devasthanam is considered one of the oldest and most famous shrines dedicated to Lord Sri Vishnumaya Kuttichathan. Situated at Karuvannur CheriyaPalam in Thrissur District of Kerala, Karanayil Devasthanam is a popular pilgrimage site for devotees who seek blessings and fulfillment of their wishes. A very unique specialty of this temple is that Vishnumaya kuttichathan swamy is living along with the 'devasthanathipathi' and other family members inside the 'home sreekovil', which was specially built by their great grandfather more than 400yrs back. In respects for this, the structure of the temple has been maintained in its original glory ever since.

                        </p>

                        <p className='about_para'>
                            Known for its religious significance, unique customs, rituals and offerings and rich cultural heritage spanning over 4 centuries, the Karayanayil family lineage is considered as one of the most powerful shrines dedicated to Lord Vishnumaya. At present the temple and its activities are under service of the 9th generation High-Priests. The temple still follows the same ancient practices, poojas, tantras and chants mantras passed on from their great forefathers.

                        </p>

                        <p className='about_para'>
                            By offering the devotees unconditional relief, solutions and peace redressing them of their deep sorrows, curses and mental agony, our temple is visited by a large number of people from various parts of the world to seek the blessings of Vishnumaya Swamy. 

                        </p>

                        <p className='about_para'>
                            There are no branches of Karuvannur Karanayil Devasthanam.

                        </p>

                    </div>

                    <div className='about_Right col-sm-12 col-md-4 col-lg-4'>

                        <div className='about_Right_Img'>
                            <img src={img1} width="350" alt='' />
                        </div>

                        <div className="container_form">
                            <form id="contact" onSubmit={handleSubmit}>
                            <h3>Online Enquiry</h3>
                            <fieldset>
                                <input placeholder="Your name" type="text" name="name" value={formData.name} onChange={handleInputChange} tabindex="1" required autofocus />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Email Address" type="email" name="email" value={formData.email} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Phone Number" type="tel" name="phone" value={formData.phone} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Country" type="text" name="country" value={formData.country} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <textarea placeholder="Type your Message Here...." name="message" value={formData.message} onChange={handleInputChange}></textarea>
                            </fieldset>
                            <fieldset>
                                <button type="submit" id="contact-submit" data-submit="...Sending">Submit</button>

           {successMessage && <div className="text-center alert alert-success">{successMessage}</div>} {/* Conditional rendering */}

                            </fieldset>


                        </form>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}


export default About;