import React from 'react';
import  { useEffect, useState } from 'react';
import "../styles/blogs.css";
import img1 from "../images/our-history.png"

const Blogs = () => {

const [blogs, setBlogs] = useState([]);

    const getBlogs = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/blogs')
        setBlogs(await response.json());
    }
    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <>
            <div className='blogs_Box container'>

                <div className='blogs_Title'>
                    <h1>Blogs</h1>
                </div>

                <div className='row'>

                  {

                             blogs.map((curElem, index) => {
                                
                                    return (
                                        <div className="col-sm-4 col-md-4 col-lg-4" key={index}>
                                           
                                            <div className="card">
                                              <img src={"https://admin.codesquarry.com/uploads/"+curElem.image} className="card-img-top" alt="..." />
                                               <div className="card-body">
                                                 <h5 style={{whiteSpace: 'nowrap', width: '250px', overflow: 'hidden', textOverflow: 'ellipsis'}} className="card-title">{curElem.title}</h5>
                                                 <p style={{whiteSpace: 'nowrap', width: '450px', overflow: 'hidden', textOverflow: 'ellipsis'}} className="card-text">{curElem.discription}</p>
                                                 <a href="#" className="btn btn-primary">View</a>
                                               </div>
                                            </div>

                                        </div>
                                    )
                                })

                  }
                    

                </div>
            </div>
        </>
    )
}

export default Blogs;