import React, { useState } from 'react';
import "../styles/festivals.css";
import CardOne from './CardOne';
import CardTwo from './CardTwo';
import CardThree from './CardThree';
import { useNavigate } from 'react-router-dom';

const Festivals = () => {

    const [active, setActive] = useState("Firstcard")

    const nevigate = useNavigate();
    const viewall = ()=>{
        nevigate("/FestivalsView")
    }

    return (

        <>
            <div className='festivals_Box'>

                <div className='festivals_Title'>
                    <h1> Festivals & Events </h1>
                </div>

                <div className='festivals_Btns'>
                    <button type="button" className="btn" onClick={() => setActive("Firstcard")}>Festivals</button>
                    <button type="button" className="btn" onClick={() => setActive("Secondcard")}>Events</button>
                </div>

                <div>
                    {active === "Firstcard" && <CardTwo title="2" />}
                    {active === "Secondcard" && <CardThree title="3" />}
                </div>

                

            </div>
        </>
    )
}



export default Festivals;