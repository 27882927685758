import React, { useState } from "react";
import "../styles/about.css";
import img1 from "../images/about.jpeg";

function Vishnumaya() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    message: ''
  });

    const [successMessage, setSuccessMessage] = useState(""); // Add this


  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch('https://admin.codesquarry.com/api/enquiry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
     .then(data => {
      console.log(data);
      setSuccessMessage("Submitted successfully"); // Set success message
      setFormData({
      name: '',
      email: '',
      phone: '',
      country: '',
      message: ''
    }); // Reset form data
    // setButtonDisabled(true); // Disable submit button
    setTimeout(() => {
      setSuccessMessage(null); // Hide success message after 3 seconds
     // setButtonDisabled(false); // Enable submit button
    }, 3000);

    })
    .catch(error => console.log(error));
  };
    return (
        <>
            <div className='about_Box container'>

                <div className='row'>

                    <div className='about_Left col-sm-12 col-md-8 col-lg-8'>



                        <p className='about_para'>
                            Vishnumaya Kuttichathan is a popular deity in the southern Indian state of Kerala, where he is worshipped as a protector and provider of blessings. The story of Vishnumaya Kuttichathan is steeped in legend and folklore, and has been passed down through the generations by oral tradition.
                        </p>

                        <p className='about_para'>
                            According to the legend, Vishnumaya Kuttichathan was a powerful magician and warrior who lived in the kingdom of Chera Nadu. He was born as the son of Lord Shiva and the goddess Kali, and was blessed with the ability to control spirits and supernatural beings. He used his powers to protect the people of his kingdom and was revered as a god by the local community.
                        </p>

                        <p className='about_para'>
                            However, Vishnumaya Kuttichathan's power and influence began to attract the attention of jealous and envious rivals, who conspired against him. They hired a group of assassins to kill him, but Vishnumaya Kuttichathan was too strong for them and managed to defeat them.
                        </p>

                        <p className='about_para'>
                            Despite his victory, Vishnumaya Kuttichathan was deeply disturbed by the betrayal and hatred he had encountered. He decided to withdraw from the world and retreated to the forests to meditate and reflect on his life.
                        </p>

                        <p className='about_para'>
                            It was during this period of meditation that Vishnumaya Kuttichathan met the sage Narada, who instructed him to build a temple in his honor. The temple was built in the village of Kalliyoor, and Vishnumaya Kuttichathan became the patron deity of the region.
                        </p>

                        <p className='about_para'>
                            Today, the temple of Vishnumaya Kuttichathan is a popular pilgrimage site, and devotees come from all over Kerala to seek his blessings and protection. The deity is believed to offer protection from evil spirits and black magic, and is also associated with fertility and prosperity. His story remains an important part of Kerala's cultural heritage, and his worship continues to be an integral part of the state's religious traditions.
                        </p>

                    </div>

                    <div className='about_Right col-sm-12 col-md-4 col-lg-4'>

                        <div className='about_Right_Img'>
                            <img src={img1} width="350" alt='' />
                        </div>

                        <div className="container_form">
                            <form id="contact" onSubmit={handleSubmit}>
                            <h3>Online Enquiry</h3>
                            <fieldset>
                                <input placeholder="Your name" type="text" name="name" value={formData.name} onChange={handleInputChange} tabindex="1" required autofocus />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Email Address" type="email" name="email" value={formData.email} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Phone Number" type="tel" name="phone" value={formData.phone} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Country" type="text" name="country" value={formData.country} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <textarea placeholder="Type your Message Here...." name="message" value={formData.message} onChange={handleInputChange}></textarea>
                            </fieldset>
                            <fieldset>
                                <button type="submit" id="contact-submit" data-submit="...Sending">Submit</button>

           {successMessage && <div className="text-center alert alert-success">{successMessage}</div>} {/* Conditional rendering */}

                            </fieldset>


                        </form>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}


export default Vishnumaya;