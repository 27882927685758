import React from 'react';
import "../styles/gallerybtn.css";
import img1 from "../images/poojs.png";

const GalleryEvents = () => {
    return (
        <>
            <div className='gallery_common_design container'>

                <img src={img1} alt='' width="250px" />
                <img src={img1} alt='' width="250px" />
                <img src={img1} alt='' width="250px" />
                <img src={img1} alt='' width="250px" />
                <img src={img1} alt='' width="250px" />
                <img src={img1} alt='' width="250px" />
                <img src={img1} alt='' width="250px" />

            </div>
        </>
    )
}


export default GalleryEvents;