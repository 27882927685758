import React from 'react';
import "../styles/cardonedescr.css"
import img1 from "../images/poojs.png";

const CardOneDescr = () => {
    return (
        <>
            <div className='cardonedescr_Box container'>
                <div className='row'>

                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <img src={img1} alt='' />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6">

                        <p>
                            Pooja is the act of paying reverence to a deity
                            through a series of rituals and invocations. A vital
                            part of pooja is establishing a spiritual connection with
                            the deity. Poojas assist individuals to welcome good fortune
                            and wealth, as well as build confidence and optimism. We provide
                            online pooja reservation services.
                        </p>

                    </div>


                </div>
            </div>
        </>
    )
}

export default CardOneDescr;