import React, { useEffect } from 'react'
import Backgroundimg from './Backgroundimg';
import Blogs from './Blogs';
import Festivals from './Festivals';
import Help from './Help';
import PoojasGallery from './PoojasGallery';
import Veda from './Veda';
import VideoGallery from './VideoGallery';



const Home = () => {
  return (
    <>
      <Backgroundimg />
      <Veda />
      <PoojasGallery />
      {/* <Services /> */}
      <Festivals />
      <Help />
      <VideoGallery />
      <Blogs />
    </>
  )
}


export default Home;