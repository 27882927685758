import  { useEffect, useState } from 'react';
import "../styles/gallerybtn.css";
import img1 from "../images/poojs.png";
import img2 from "../images/two.jpg";
import img3 from "../images/three.jpg";

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


const VideoAll = () => {

const [data, setData] = useState([]);

const getData = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/videos')
        setData(await response.json());
    }

    useEffect(() => {
        getData();
    }, []);

const onInit = () => {
        console.log('lightGallery has been initialized');
    };

  return (
    <>
      

      <div className='container'>

       <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >

       {

                             data.map((item, index) => {

                               let id= item.video_url.split("=");
                               console.log(id,id[1])

                                
                                    return (


                                    <a class='gallery_img' href={item.video_url}><iframe width="400px" height="315" src={"https://www.youtube.com/embed/"+id[1]} frameborder="0" allowfullscreen=""></iframe></a>
                

                                    )
                                })

                  }

        </LightGallery>


       
          
      

      </div>

      

    </>
  )
}


export default VideoAll;
