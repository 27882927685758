import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../styles/poojasgallery.css";

const PoojasGallery = () => {
   
    const nevigate = useNavigate();
    const viewall = ()=>{
        nevigate("/Poojas")
    }

    const booking = () => {
        nevigate("/Booking")
    }


    const [poojas, setPoojas] = useState([]);

    const getPoojas = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/poojas')
        setPoojas(await response.json());
    }
    useEffect(() => {
        getPoojas();
    }, []);
    
    return (
        <>
            <div className='container'>

                <div className='blogs_Title'>
                    <h1> <span> ॐ </span> Poojas <span> ॐ </span> </h1>
                </div>

                <div className='PoojasGallery_Box row'>

                 {

                             poojas.slice(0,12).map((curElem, index) => {
                                
                                    return (
                                        

                                        <div className='PoojasGallery_Cards col-sm-3 col-md-3 col-lg-3'>
                                        <a onClick={() => booking()} href="javascript:void()" className="pooja-link">
                        <div className="card PoojasGallery_Border">
                            <div className="card-body PoojasGallery_Cover block">
                                <h4 className="card-title">{curElem.pooja_title}</h4>
                                <h5 className="card-subtitle mb-2 text-muted">Rs {curElem.price}</h5>
                            </div>
                        </div>
                        </a>
                    </div>

                                    )
                                })

                  }


                    

                </div>

                <div className='poojagalleryButton'>
                    <a href='' onClick={() => viewall()} className='poojagalleryBtn'>View All</a>
                </div>

            </div>
        </>
    )
}


export default PoojasGallery;