import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../styles/poojas.css";

const Poojas = () => {

    const nevigate = useNavigate();
    const booking = () => {
        nevigate("/Booking")
    }


    const [poojas, setPoojas] = useState([]);

    const getPoojas = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/poojas')
        setPoojas(await response.json());
    }
    useEffect(() => {
        getPoojas();
    }, []);


    return (
        <>
            <div className='container'>

                <h1 className='poojas_Title'>POOJAS</h1>

                <div className='poojas_Wrapper  row'>


                {

                             poojas.map((curElem, index) => {
                                
                                    return (
                                        

                                        <div className='PoojasGallery_Cards col-sm-3 col-md-3 col-lg-3'>
                                        <a onClick={() => booking()} href="javascript:void()" className="pooja-link">
                        <div className="card PoojasGallery_Border">
                            <div className="card-body PoojasGallery_Cover block">
                                <h3 className="card-title">{curElem.pooja_title}</h3>
                                <h5 className="card-subtitle mb-2 text-muted">Rs {curElem.price}</h5>
                            </div>
                        </div>
                        </a>
                    </div>

                                    )
                                })

                  }


                    
                    

                </div>

            </div>
        </>
    )
}


export default Poojas;