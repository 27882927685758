import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "./components/About";
import Vishnumaya from "./components/Vishnumaya";
import Blogs from "./components/BlogsAll";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Poojas from "./components/Poojas";
import FestivalsView from "./components/FestivalsView";
import CardOneDescr from "./components/CardOneDescr";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Booking from "./components/Booking";
import SimpleReactLightbox from 'simple-react-lightbox';




const App = () => {
  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/vishnumaya" element={<Vishnumaya />} />
        <Route path="/poojas" element={<Poojas />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/festivalsview" element={<FestivalsView />} />
        <Route path="/cardonedescr" element={<CardOneDescr />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<Blog />} />
      </Routes>

      <Footer />
    </>
  );
};

export default App;
