import React, { useState } from 'react';
import "../styles/festivals.css";
import img1 from "../images/festival1.jpeg";
import img2 from "../images/festival2.jpeg";
import img3 from "../images/festival3.jpeg";
import img4 from "../images/festival4.jpeg";
import CardOne from './CardOne';
import CardTwo from './CardTwo';
import CardThree from './CardThree';
import { useNavigate } from 'react-router-dom';

const Festivals = () => {

    const nevigate = useNavigate();
    const viewall = ()=>{
        nevigate("/FestivalsView")
    }

    return (

        <>
            <div className='festivals_Box'>

                <div className='blogs_Title'>
                    <h1> <span> ॐ </span> Our Festivals <span> ॐ </span> </h1>
                </div>

                
                <div>
                    <div className='row container festival_Box'>


                        <div className="col-lg-6 festival_card">
                          <div className="card">
                           <img src={img1} className="card-img-top" alt="..." />
                           <div className="cardone_body">
                             <div>
                               <h4>THIRAVELLATTU</h4>
                               <p>MAHOLSAVAM</p>
                             </div>
                           <div>
                            <i className="fa-solid fa-arrow-right"
                             style={{ fontSize: "1.5rem", cursor:"pointer" }}></i>
                        </div>
                    </div>
                    </div>
                </div>




   <div className="col-lg-6 festival_card">
                          <div className="card">
                    <img src={img2} className="card-img-top" alt="..." />
                    <div className="cardone_body">
                        <div>
                            <h4>THOTTAMPATTU</h4>
                            <p>MAHOLSAVAM</p>
                        </div>
                        <div>
                            <i className="fa-solid fa-arrow-right"
                            
                             style={{ fontSize: "1.5rem", cursor:"pointer" }}></i>
                        </div>
                    </div>
                    </div>
                </div>



   <div className="col-lg-6 festival_card">
                          <div className="card">
                    <img src={img3} className="card-img-top" alt="..." />
                    <div className="cardone_body">
                        <div>
                            <h4>KALAMPATTU</h4>
                            <p>MAHOLSAVAM</p>
                        </div>
                        <div>
                            <i className="fa-solid fa-arrow-right"
                            
                             style={{ fontSize: "1.5rem", cursor:"pointer" }}></i>
                        </div>
                    </div>
                    </div>
                </div>



                <div className="col-lg-6 festival_card">
                          <div className="card">
                    <img src={img4} className="card-img-top" alt="..." />
                    <div className="cardone_body">
                        <div>
                            <h4>MAHA CHANDIKA</h4>
                            <p>HOMAM</p>
                        </div>
                        <div>
                            <i className="fa-solid fa-arrow-right"
                            
                             style={{ fontSize: "1.5rem", cursor:"pointer" }}></i>
                        </div>
                    </div>
                    </div>
                </div>


             



                     


                

                

            </div>
                </div>

                <div className='festivalsVierButton'>
                    <a href='' className='festivalsVierBtn' 
                    onClick={()=>viewall()} >View All</a>
                </div>

            </div>
        </>
    )
}



export default Festivals;