import React from 'react';
import "../styles/footer.css";
import { NavLink } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <div className='footer_Cover container-fluid'>
                <div className='row footer_Box '>

                    <div className='col-sm-5'>
                        <h4>About Us</h4>
                        <br/>
                        <p>
                            Karyanayil Devasthanam is considered one of the oldest and most famous shrines dedicated to Lord Sri Vishnumaya Kuttichathan
                        </p>
                        <p>
                            <span style={{ marginRight: "7px" }}><i className="fa-solid fa-phone"></i></span>
                            (+91) 960 553 5555
                        </p>
                        <p>
                            <span style={{ marginRight: "7px" }}><i className="fa-regular fa-envelope"></i></span>
                            vishnumaya@kuttichathan.com
                        </p>
                        <p>
                            Karanayil Devasthanam, Karuvannur CheriyaPalam, <br/>Thrissur District 680711
                        </p>
                    </div>

                    <div className='col-sm-2'>
                        <h4>Quick Links</h4>
                        <br/>
                        <NavLink to="/"><p>Home</p></NavLink>
                        <NavLink to="/about"><p>About</p></NavLink>
                        <NavLink to="/festival"><p>Festival</p></NavLink>
                        <NavLink to="/gallery"><p>Gallery</p></NavLink>
                        <NavLink to="/contact"><p>Contact</p></NavLink>
                      
                    </div>

                    <div className='col-sm-2'>
                        <h4>Pooja</h4>
                        <br/>
                        <p>Pushpanjali</p>
                        <p>Ghee Vilakku </p>
                        <p>Panaka Pooja </p>
                        <p>Thrikala Pooja </p>
                        <p>Coin Archana </p>
                    </div>

                    <div className='col-sm-3'>
                        <h4>Social Media</h4>
                        <br/>
                        <a href="http://fb.com/kuttichathantemple"><i className="fa-brands fa-facebook-f footer_fa"></i></a>&nbsp;&nbsp;
                        <a href="http://instagram.com/karanayildevasthanam"><i className="fa-brands fa-instagram footer_in"></i></a>&nbsp;&nbsp;
                        <a href="https://www.youtube.com/@KARANAYILDEVASTHANAM"><i className="fa-brands fa-youtube footer_yt"></i></a>&nbsp;&nbsp;
                        <a href="http://http://twitter.com/kuttichathan_"><i className="fa-brands fa-twitter footer_yt"></i></a>&nbsp;&nbsp;
                    </div>

                    <hr style={{ marginTop: "4rem", marginBottom: "3rem" }} />

                    <div className='footer_copyright'>
                        <p className='footer_copyOne'>
                            Copyright © 2023 Karanayil Devasthanam | All Rights Reserved
                        </p>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Footer;