import React from 'react';
import "../styles/navbar.css";
import img1 from "../images/KKD_Logo.png";
import idol from "../images/Idolicon.png";
import navbg from "../images/navbg.jpg";
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            <div className='container-fluid navbar_Container'>
                <div className='row navbar_box'>

                    <div className='idol_img col-sm-1 col-md-1 col-lg-1'>
                            <img src={idol} alt='' width="120px" />
                    </div>

                    <div className='row p-2 col-sm-10 col-md-10 col-lg-10'>


                    <div className='navbar_Logo col-sm-6 col-md-6 col-lg-6'>
                        <NavLink to="/">
                            <img src={img1} alt='' width="100%" height="100%" />
                        </NavLink>
                    </div>

                    <div className='col-sm-6 col-md-6 col-lg-6'>

                        <div>
                            <ul className='nav_info'>
                            <li className='a'>
                                    <span style={{ marginRight: "6px" }}><i className="fa-regular fa-envelope"></i></span>
                                    vishnumaya@kuttichathan.com
                                </li>
                                <li className='a'>
                                    <span style={{ marginRight: "6px" }}><i className="fa-solid fa-phone"></i></span>
                                    (+91) 9605535555
                                </li>
                            </ul>
                        </div>

                       

                        <nav class="navbar navbar-expand-lg">
                         <div class="container-fluid">
                        <div className='menu_logo'>
                             <NavLink to="/">
                            <img src={img1} alt='' width="200px" height="" />
                        </NavLink>
                        </div>
                             <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                            </button>
                       <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                       <ul className='navbar_ul navbar-nav'>
                        <li class="nav-item">
                                <NavLink to="/" exact ClassName="active" className="nav-link navbar_Hover">HOME</NavLink>
                        </li>
                        
                        <li class="nav-item dropdown">
                         <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          ABOUT
                         </a>
                            <ul class="dropdown-menu">
                              <li><NavLink to="/about" className="dropdown-item" exact ClassName="active" className="nav-link" href="#">About</NavLink></li>
                              <li><NavLink to="/vishnumaya" className="dropdown-item" exact ClassName="active" className="nav-link" href="#">Vishnumaya</NavLink></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                                <NavLink to="/FestivalsView" exact ClassName="active" className="nav-link navbar_Hover">FESTIVALS</NavLink>
                        </li>
                        <li class="nav-item">
                                <NavLink to="/poojas" exact ClassName="active" className="nav-link navbar_Hover">POOJAS</NavLink>
                        </li>
                        <li class="nav-item">
                                <NavLink to="/gallery" exact ClassName="active" className="nav-link navbar_Hover">GALLERY</NavLink>
                        </li>
                        <li class="nav-item">
                                <NavLink to="/contact" exact ClassName="active" className="nav-link navbar_Hover">CONTACT</NavLink>
                        </li>
                      </ul>
                     </div>
                   </div>
                 </nav>


                    </div>


                        
                    </div>

                    <div className='idol_img col-sm-1 col-md-1 col-lg-1'>
                            <img src={idol} alt='' width="120px" />
                    </div>

                    
                    

                </div>
            </div>
        </>
    )
}

export default Navbar;