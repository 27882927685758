import React from 'react';
import { useParams } from 'react-router-dom';
import  { useEffect, useState } from 'react';
import "../styles/blog.css";
import img1 from "../images/our-history.png"

const Blogs = () => {

const { id } = useParams();

console.log(id)


const [blogs, setBlogs] = useState([]);

const getBlogs = async () => {
  const response = await fetch(`https://admin.codesquarry.com/api/blog/${id}`);
  setBlogs(await response.json());
}

useEffect(() => {
  getBlogs();
}, []);

console.log(blogs);

    return (
        <>
            <div className='blogs_Box container'>

                <div className='blogs_Title'>
                    <h1>Single Blog</h1>
                </div>


                <article class="blog-post">
                  <img src={"https://admin.codesquarry.com/uploads/"+blogs.image} width="600px" height="400px" className="card-img-top" alt="..." />

                  <h2 class="blog-title">{blogs.title}</h2>

                  <p>{blogs.discription}</p>
                  
                </article>

                      
            </div>
        </>
    )
}

export default Blogs;