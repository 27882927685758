import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../styles/veda.css";

const Veda = () => {

const nevigate = useNavigate();
    const about = () => {
        nevigate("/About")
    }

    return (
        <>

            <div className='veda_Box container'>

                <div className='row'>
                    <div className='veda_right col-sm-12 col-md-8 col-lg-8'>

                        <h1 className='veda_right_subtitle'>
                            About Us
                        </h1>
                        <br />
                        
                        <p className='veda_right_para'>
                        Karyanayil Devasthanam is considered one of the oldest and most famous shrines dedicated to Lord Sri Vishnumaya Kuttichathan. Situated at Karuvannur CheriyaPalam in Thrissur District of Kerala, Karanayil Devasthanam is a popular pilgrimage site for devotees who seek blessings and fulfillment of their wishes. A very unique specialty of this temple is that Vishnumaya kuttichathan swamy is living along with the 'devasthanathipathi' and other family members inside the 'home sreekovil', which was specially built by their great grandfather more than 400yrs back. In respects for this, the structure of the temple has been maintained in its original glory ever since.

                        </p>

                        <br />

                        <a href=''>
                            <button onClick={() => about()} type="button" class="veda_right_btn">
                                LEARN MORE
                                <span className='veda_arrow'>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </span>
                            </button>
                        </a>

                    </div>

                    <div className='veda_left col-sm-12 col-md-4 col-lg-4'>

                        <br />
                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/3Gf8M9zQGLw?&loop=1">
                        </iframe>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Veda;
