import React from 'react';
import  { useEffect, useState } from 'react';
import "../styles/cardone.css";
import img1 from "../images/poojs.png";
import { useNavigate } from 'react-router-dom';

const CardThree = () => {


const [data, setData] = useState([]);

    const getData = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/events')
        setData(await response.json());
    }
    const festival = data.filter(item => item.event_type === "Event");

    useEffect(() => {
        getData();
    }, []);


    const nevigate = useNavigate();
    const description = ()=>{
        nevigate("/CardOneDescr")
    }

    return (
        <>
                <div className='row container festival_Box'>

            {

                             festival.map((curElem, index) => {
                                
                                    return (
                                       


                                        <div className="col-lg-6 festival_card">
                          <div className="card">
                           <img src={"https://admin.codesquarry.com/uploads/"+curElem.image} className="card-img-top" alt="..." />
                           <div className="cardone_body">
                             <div>
                               <h4>{curElem.title}</h4>
                             </div>
                           <div>
                        </div>
                    </div>
                    </div>
                </div>


                                    )
                                })

                  }

                                
                

            </div>
        </>
    )
}

export default CardThree;