import React from 'react';
import { Link } from 'react-router-dom';
import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../styles/blogs.css";
import img1 from "../images/our-history.png"

const Blogs = () => {

const nevigate = useNavigate();
    const viewblogs = () => {
        nevigate("/blog")
    }

    const viewall = ()=>{
        nevigate("/blogs")
    }


const [blogs, setBlogs] = useState([]);

    const getBlogs = async () => {
        const response = await fetch('https://admin.codesquarry.com/api/blogs')
        setBlogs(await response.json());
    }
    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <>
            <div className='blogs_Box container'>

                <div className='blogs_Title'>
                    <h1> <span> ॐ </span> Blogs <span> ॐ </span> </h1>
                </div>

                <div className='row'>

                  {

                             blogs.slice(0,3).map((curElem, index) => {
                                
                                    return (
                                        <div className="col-sm-4 col-md-4 col-lg-4" key={index}>
                                           
                                            <div className="card">
                                              <img src={"https://admin.codesquarry.com/uploads/"+curElem.image} className="card-img-top" alt="..." />
                                               <div className="card-body">
                                                 <h5 className="card-title">{curElem.title}</h5>
                                                 <p style={{whiteSpace: 'nowrap', width: '250px', overflow: 'hidden', textOverflow: 'ellipsis'}} className="card-text">{curElem.discription}</p>
                                                 <Link to={"/blog/"+curElem.id} className="btn btn-primary">
                                                    Read more
                                                 </Link>
                                               </div>
                                            </div>

                                        </div>
                                    )
                                })

                  }
                    

                </div>

                <div className='poojagalleryButton'>
                    <Link to='/blogs' className="poojagalleryBtn">
                       View All
                    </Link>
                </div>

            </div>
        </>
    )
}

export default Blogs;