import React, { useState, useEffect } from "react";
import "../styles/booking.css";

function Booking() {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    price: "",
    name: "",
    star: "",
    address: "",
    pincode: "",
    email: "",
    mobile: ""
  });

    const [successMessage, setSuccessMessage] = useState(""); // Add this


  useEffect(() => {
    fetch("https://admin.codesquarry.com/api/poojas")
      .then(response => response.json())
      .then(data => setOptions(data))
      .catch(error => console.log(error));
  }, []);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSelectChange = (event) => {
  const selectedPooja = options.find((option) => option.pooja_title === event.target.value);
  setSelectedOption(event.target.value);
  setFormData({
    ...formData,
    pooja_title: selectedPooja.pooja_title,
    price: selectedPooja.price
  });
};

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      ...formData,
    pooja_title: selectedOption // add the selectedOption value
    };

    console.log(data)


    fetch("https://admin.codesquarry.com/api/poojaBooking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
       .then(data => {
      console.log(data);
      setSuccessMessage("Submitted successfully"); // Set success message
      setFormData({
       price: "",
    name: "",
    star: "",
    address: "",
    pincode: "",
    email: "",
    mobile: ""
    }); // Reset form data
    // setButtonDisabled(true); // Disable submit button
    setTimeout(() => {
      setSuccessMessage(null); // Hide success message after 3 seconds
     // setButtonDisabled(false); // Enable submit button
    }, 3000);

    })
      .catch(error => console.log(error));
  };


  return (


   



                          <div className='Booking_Box container' >

                                <form onSubmit={handleSubmit}>


                            <div className='row'>
                                <div className='table-responsive col-sm-12'>
                                    <table class="table">

                                        <thead>


                                            <tr>
                                        
                                                <th>Pooja</th>
                                                <th>Price</th>
                                                <th>Name</th>
                                                <th>Star</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='col-sm-5 col-md-5 col-lg-5'>
                                                    <select class="form-select" name="pooja_title" value={selectedOption} onChange={handleSelectChange} required>
                                                        {options.map(option => (
                                                         <option key={option.pooja_title} value={option.pooja_title}>
                                                            {option.pooja_title}
                                                           </option>
                                                        ))}
                                                    </select>
                                                </td>

                                                <td className='col-sm-2 col-md-2 col-lg-2'>
                                                    <input className="form-control" type="number" name="price" value={formData.price} onChange={handleInputChange} disabled/>
                                                </td>
                                                <td className='col-sm-3 col-md-3 col-lg-3'>
                                                    <input className="form-control" type="text" name="name" value={formData.name} onChange={handleInputChange} required/>
                                                </td>
                                                <td className='col-sm-2 col-md-2 col-lg-2'>
                                                    <input className="form-control"type="text" name="star" value={formData.star} onChange={handleInputChange} required/>
                                                </td>

                                            </tr>

                                        </tbody>

                                    </table>

                                </div>
                            </div>


                            <div className='row'>
                                            <div className='row'>
                                                <div className="mb-3 col-sm-12 col-md-12 col-lg-6">
                                                    <label for="exampleInputtext" className="form-label">Address</label>
                                                    <input type="text" name="address" value={formData.address} onChange={handleInputChange} className="form-control" required/>
                                                </div>
                                                <div className="mb-3 col-sm-12 col-md-12 col-lg-6">
                                                    <label for="exampleInputtext" className="form-label">Pincode</label>
                                                    <input type="text" name="pincode" value={formData.pincode} onChange={handleInputChange} className="form-control" required/>
                                                </div>
                                            </div>
                    
                                            <div className='row'>
                                                <div className="mb-3 col-sm-12 col-md-12 col-lg-6">
                                                    <label for="exampleInputtext" className="form-label">Email</label>
                                                    <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="form-control" required/>
                                                </div>
                                                <div className="mb-3 col-sm-12 col-md-12 col-lg-6">
                                                    <label for="exampleInputtext" className="form-label">Mobile</label>
                                                    <input type="text" name="mobile" value={formData.mobile} onChange={handleInputChange} className="form-control" required/>
                                                </div>
                                            </div>
                                           
                                    </div>
                                    <button type="submit" className="btn btn-secondary">Submit</button>
           {successMessage && <div className="text-center alert alert-success">{successMessage}</div>} {/* Conditional rendering */}

                                        </form>
                               </div> 



  );
}

export default Booking;
