import React, { useState } from 'react'
import "../styles/gallery.css";
import GalleryAll from './GalleryAll';
import VideoAll from './VideoAll';
import GalleryEvents from './GalleryEvents';
import GalleryKaruvannur from './GalleryKaruvannur';
import { SRLWrapper } from 'simple-react-lightbox';


const Gallery = () => {

    const [active, setActive] = useState("Firstcard")

    return (
        <>
            <div className='gallery_Box'>

                <div className='gallery_Btns'>
                    <button type="button" className="btn" onClick={() => setActive("Firstcard")}>Images</button>
                    <button type="button" className="btn" onClick={() => setActive("Secondcard")}>Videos</button>
                    
                </div>
                <SRLWrapper>
                    <div>
                        <div>
                            {active === "Firstcard" && <GalleryAll title="1" />}
                        </div>
                        <div>
                            {active === "Secondcard" && <VideoAll title="2" />}
                        </div>
                        <a href="">
                            {active === "Thirdcard" && <GalleryEvents title="3" />}
                        </a> 
                    </div>
                </SRLWrapper>

            </div>
        </>
    )
}


export default Gallery;




// import React, { useState, useEffect } from 'react'
// import "../styles/gallery.css";
// import img1 from "../images/poojs.png";
// import { SRLWrapper } from 'simple-react-lightbox';


// const Images = [
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
//     <img src={img1} alt='' width="250px" />,
// ]

// const images = [
//     {id:'1', imageName: 'poojs.png', tag:'Temple'},
//     {id:'2', imageName: 'poojs.png', tag:'Karuvannur'},
//     {id:'3', imageName: 'poojs.png', tag:'Temple'},
//     {id:'4', imageName: 'poojs.png', tag:'Temple'},
//     {id:'5', imageName: 'poojs.png', tag:'Karuvannur'},
//     {id:'6', imageName: 'poojs.png', tag:'Temple'},
//     {id:'7', imageName: 'poojs.png', tag:'Temple'},
//     {id:'8', imageName: 'poojs.png', tag:'Karuvannur'},
//     {id:'9', imageName: 'poojs.png', tag:'Roopakalam'},
//     {id:'10', imageName: 'poojs.png', tag:'Karuvannur'},
//     {id:'11', imageName: 'poojs.png', tag:'Celebrities'},
//     {id:'12', imageName: 'poojs.png', tag:'Festivals'},
//     {id:'13', imageName: 'poojs.png', tag:'Celebrities'},
//     {id:'14', imageName: 'poojs.png', tag:'Festivals'},
//     {id:'15', imageName: 'poojs.png', tag:'Festivals'},
//     {id:'16', imageName: 'poojs.png', tag:'Celebrities'}
// ]



// function Gallery() {
//     const [tag, setTag] = useState('free');
//     const [filteredImages, setFilteredImages] = useState([]);

//     useEffect( () => {
//         tag == 'all' ? setFilteredImages(images) : setFilteredImages(images.filter(image =>  image.tag== tag ))
//     }, [tag])
//   return (
//     <div className='App' handleSetTag={setTag}>
//         <div className='tags'>
//             <TagButton  name="all" handleSetTag={setTag}/>
//             <TagButton  name="Karuvannur" handleSetTag={setTag}/>
//             <TagButton  name="Temple" handleSetTag={setTag}/>
//             <TagButton  name="Festivals" handleSetTag={setTag}/>
//             <TagButton  name="Roopakalam" handleSetTag={setTag}/>
//             <TagButton  name="Celebrities" handleSetTag={setTag}/>
//         </div>

//         <SRLWrapper>
//             <div className="container">
//                 {filteredImages.map(image => 
//                     <div key={image.id} className="image-card">
//                         <a href="{img1}">
//                             <img className="image" src={img1} alt="" />
//                         </a>
//                     </div>)
//                 }
//             </div>
//         </SRLWrapper>
//     </div>
//   )
// }

// const TagButton = ({ name, handleSetTag }) => {
//     return <button className='tag' onClick={() => handleSetTag(name)}>{name.toUpperCase()}</button>
// }

// export default Gallery
