import React, { useState } from "react";
import "../styles/contact.css";


function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    message: ''
  });

    const [successMessage, setSuccessMessage] = useState(""); // Add this


  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch('https://admin.codesquarry.com/api/enquiry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
     .then(data => {
      console.log(data);
      setSuccessMessage("Submitted successfully"); // Set success message
      setFormData({
      name: '',
      email: '',
      phone: '',
      country: '',
      message: ''
    }); // Reset form data
    // setButtonDisabled(true); // Disable submit button
    setTimeout(() => {
      setSuccessMessage(null); // Hide success message after 3 seconds
     // setButtonDisabled(false); // Enable submit button
    }, 3000);

    })
    .catch(error => console.log(error));
  };



    return (
        <>
            <div className='contact_Box container'>
                <div className='row'>
                <h3 style={{ textAlign: "center", paddingTop:"2rem", paddingBottom:"3rem" }}>Contact Us</h3>
                    <div className='contact_Adress col-sm-12 col-md-12 col-lg-8'>
                        <div>                            
                            <h1 className='contact_Title'>KARANAVIL DEVASTHANAM <br /> SHREE VISHNUMAYA TEMPLE</h1>
                            <h5>Phone (+91) 9605535555</h5>
                            <h5>Email vishnumaya@kuttichathan.com</h5>
                        </div>
                        <div>
                        <iframe width="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31391.95016514166!2d76.15105021470325!3d10.422068423996143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7f6cca0b3e685%3A0xc9fa92263595676f!2sKARUVANNUR%20KARANAYIL%20DEVASTHANAM!5e0!3m2!1sen!2sin!4v1675852380313!5m2!1sen!2sin" className='contact_Googlemap' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div className='contact_Form col-sm-12 col-md-12 col-lg-4'>
                         <form id="contact" onSubmit={handleSubmit}>
                            <h3>Online Enquiry</h3>
                            <fieldset>
                                <input placeholder="Your name" type="text" name="name" value={formData.name} onChange={handleInputChange} tabindex="1" required autofocus />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Email Address" type="email" name="email" value={formData.email} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Phone Number" type="tel" name="phone" value={formData.phone} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <input placeholder="Your Country" type="text" name="country" value={formData.country} onChange={handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <textarea placeholder="Type your Message Here...." name="message" value={formData.message} onChange={handleInputChange}></textarea>
                            </fieldset>
                            <fieldset>
                                <button type="submit" id="contact-submit" data-submit="...Sending">Submit</button>

           {successMessage && <div className="text-center alert alert-success">{successMessage}</div>} {/* Conditional rendering */}

                            </fieldset>


                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Contact;