import React from 'react';
import "../styles/help.css";
import img1 from "../images/1.png";
import img2 from "../images/2.png"

const Help = () => {
    return (
        <>
            <div className='container help_BOX'>

                <div className='help_Title'>
                    <h5> <span> ॐ </span>  WAYS WE CAN HELP  <span> ॐ </span> </h5>
                    <h1>We are ready to serve you</h1>
                </div>

                <div className='help_Para'>
                    <div className='help_One'>
                        <h5>Need Help, Call Our HOTLINE!</h5>
                        <h1>(+91) 960 553 5555</h1>
                        <div className='help_Img1'>
                            <img src={img1} alt='' />
                        </div>
                    </div>
                    <div className='help_Two'>
                        <h5>or</h5>
                    </div>
                    <div className='help_Three'>
                        <h5>Temple  Newsletter</h5>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder='Enter email address' />
                            <span class="input-group-text" id="inputGroup-sizing-default">
                            <i class="fa-regular fa-envelope"></i>
                            </span>
                        </div>
                        <div className='help_Img2'>
                            <img src={img2} alt='' />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Help;