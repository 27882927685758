import React from 'react';
import "../styles/videogallery.css";

const VideoGallery = () => {

    return (
        <>
            <div className='videogallery_Box container'>
                <div className='row'>

                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <h2>Video Gallery</h2>
                        <br />
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/3Gf8M9zQGLw?&loop=1">
                        </iframe>
                    </div>

                    <div className="accordion col-sm-12 col-md-12 col-lg-6" id="accordionExample">

                        <h1>Frequently Asked Questions</h1>

                        <br />

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span className="fw-bold">Are there any branches for Karuvannur Karanayil &nbsp;&nbsp;Devasthanam?</span><br />&nbsp;&nbsp;
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    No, Karuvannur Karanayil Devastham has no branches.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <b>How to reach Devasthanam?</b>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    By Bus  :- From Trichur town: From Sakthan Thampuran bus-stand, board the bus to IRINJALAKKUDA KODUNGALOOR and alight at KARUVANNUR CHERIYAPALAM stop. Pick an autorikshaw to KRUVANNUR KARANAYIL DEVASTHANAM
                                    By Taxi or Auto:- From trichur town call auto or taxi to KARUVANNUR CHERIYAPALAM KARANAYIL DEVASTHANAM(call us from taxi we will explain the correct route to your driver)
                                    Call us on +91 960 553 5555 and we shall explain the route to your driver
                                    Google Map :- message ‘SHARE MAP’  from your whats app number to +91 960 553 5555. You shall receive the Google Map Location automatically.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <b>Is prior appointment required?</b>
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    It is adviced to take an appointment atleast one day in advance.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapsefive">
                                    <b>Are there any food restrictions when visiting the temple?</b>
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Other than Beef, devotees have no restriction in food at all.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                    <b>Can I get in touch with Karanayil Devasthanam if I am &nbsp;outside India?</b>
                                </button>
                            </h2>
                            <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    One may contact us via eMail:vishnumaya@kuttichathan.com or on our Contact number: (+91) 960 553 5555. We are also available on Whatsapp on the same number.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesev" aria-expanded="false" aria-controls="collapsesev">
                                    <b>How long does it take to see results?</b>
                                </button>
                            </h2>
                            <div id="collapsesev" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Vishnumaya Kuttichathan Swamy is a very powerful diety and all his devotees have been blessed with immedite results. Based on the intensity of your problems/situation, we provide the solutions. There is no hard and fast time rule that results will be seen within a specified date.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <b>How old is Karanayil Devasthanam?</b>
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Karnayil Devasthanam is over 400 years old and the 9th generation of high priests are presently in charge of serving the Devasthanm.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


export default VideoGallery;