import React from 'react';
import "../styles/backgroundimg.css";
import img1 from "../images/one.jpg";
import img2 from "../images/two.jpg";
import img3 from "../images/three.jpg";


const Backgroundimg = () => {
    return (
        <>
            <div className='container-fluid'>
            <div className='backgroundimg'>
                    <div className="row">

                        <div className='col-sm-11 col-md-11 col-lg-11'>
                       
                        <div className="carousel-it">
                            <img src={img2} className="d-block w-100 img-fluid" alt="..." width="100%"/>
                        </div>

                        </div>

                        <div className='col-sm-1 col-md-1 col-lg-1'>
                        <ul className=' social_Icon'>
                            <li>
                               <span>
                                <a href="http://fb.com/kuttichathantemple"><i className="fa-brands fa-facebook-f footer_fa"></i></a>
                               </span>
                            </li>
                            <li>
                               <span>
                                <a href="http://instagram.com/karanayildevasthanam"><i className="fa-brands fa-instagram footer_in"></i></a>
                               </span>
                            </li>
                            <li className=''>
                               <span>
                                <a href="https://www.youtube.com/@KARANAYILDEVASTHANAM"><i className="fa-brands fa-youtube footer_yt"></i></a>
                               </span>
                            </li>
                            <li>
                               <span>
                                <a href="http://http://twitter.com/kuttichathan_"><i className="fa-brands fa-twitter footer_yt"></i></a>
                               </span>
                            </li>
                        </ul>
                        <ul className='navbar_Icon'>
                            
                        </ul>
                    </div>

                    
                </div>
            </div>
            </div>
        </>
    )
}

export default Backgroundimg;